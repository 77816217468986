var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"contentOutput",class:[
    'output-block__result',
    { 'container x-scroll x-mandatory mobile': _vm._isMobile }
  ],attrs:{"id":"output-block__result"}},[(!_vm._isMobile)?_c('div',{class:['content', { mobile: _vm._isMobile }]},[_c('div',{staticClass:"number-wrapper"},[_vm._l((_vm.rows),function(row){return [_c('div',{key:row,staticClass:"number"},[(row === 1 || row % 5 === 0)?_c('span',[_vm._v(_vm._s(row))]):_vm._e()])]})],2),_c('div',{staticClass:"value-wrapper"},[_vm._l((_vm.encodeResult),function(row){return _vm._l((_vm.filteredRow(row.value, row.sequence)),function(letter,index){return _c('span',{key:`${row.sequence}-${letter}-${index}`,class:['letter-box', _vm.activeSumbol(row.sequence - 1, index)],attrs:{"id":`s-${row.sequence - 1}-${index}`},on:{"mouseover":function($event){return _vm.selectDNASeq(row.sequence - 1, letter, index)}}},[(
              !_vm.activeSumbol(row.sequence - 1, index).includes('highlight') &&
                !_vm.activeSumbol(row.sequence - 1, index).includes('metadata')
            )?_c('span',{staticClass:"wrapper"},[_c('span',{class:['letter', letter.toLowerCase()]},[_vm._v(" "+_vm._s(letter)+" ")])]):_vm._e()])})})],2)]):[_c('ul',{staticClass:"value-wrapper"},[_vm._l((_vm.encodeResult),function(row){return _vm._l((_vm.filteredRow(row.value, row.sequence)),function(letter,index){return _c('li',{key:`${row.sequence}-${letter}-${index}`,class:['letter-box', _vm.activeSumbol(row.sequence - 1, index)],attrs:{"id":`s-${row.sequence - 1}-${index}`},on:{"mouseover":function($event){return _vm.selectDNASeq(row.sequence - 1, letter, index)}}},[(
              !_vm.activeSumbol(row.sequence - 1, index).includes('highlight') &&
                !_vm.activeSumbol(row.sequence - 1, index).includes('metadata')
            )?_c('span',{staticClass:"wrapper"},[_c('span',{class:['letter', letter.toLowerCase()]},[_vm._v(" "+_vm._s(letter)+" ")])]):_vm._e()])})})],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }