var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"interactive-page"},[_c('div',{staticClass:"interactive-page__header"},[(_vm.showHowItWorks === false)?_c('div',{staticClass:"input-block__actions"},[_c('div',{staticClass:"input-block__actions-wrapper"},[_c('div',{staticClass:"input-block__actions-general"},[(_vm.encodeStatus === 'ready' || _vm.encodeStatus === 'edit')?_c('v-btn',{staticClass:"actions-encode",attrs:{"disabled":_vm.textarea.length > _vm.textareaLimit || _vm.textarea.length === 0},on:{"click":_vm.encode}},[(!_vm._isMobile)?_c('div',{staticClass:"icon"},[_c('div',{staticClass:"encode-icon"})]):_vm._e(),_vm._v(" encode ")]):(_vm.encodeStatus === 'proseccing')?_c('v-btn',{staticClass:"actions-proseccing"},[(!_vm._isMobile)?_c('div',{staticClass:"icon"},[_c('div',{staticClass:"proseccing-icon"})]):_vm._e(),_vm._v(" processing ")]):(
                _vm.encodeStatus === 'finished' && _vm.encodeStatus !== 'edit'
              )?_c('v-btn',{class:{ enabled: _vm.encodeStatus === 'finished' },on:{"click":_vm.edit}},[_c('div',{staticClass:"icon"},[_c('div',{staticClass:"edit-icon"})]),_vm._v(" edit text ")]):_vm._e(),(_vm.encodeStatus !== 'ready' && _vm.encodeStatus === 'edit')?_c('v-btn',{class:[
                'actions-cancel',
                { enabled: _vm.encodeStatus === 'finished' }
              ],on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm._isMobile ? 'X' : 'Cancel')+" ")]):_vm._e()],1),_c('div',{staticClass:"dialog-box-wrapper"},[_c('v-btn',{class:['actions-find', 'enabled'],on:{"click":function($event){_vm.showExtraMenu === false ? (_vm.showHowItWorks = true) : null}}},[_c('div',{staticClass:"icon"},[_c('div',{staticClass:"i-icon"})])]),_c('v-btn',{class:[
                'actions-find',
                { enabled: _vm.encodeStatus === 'finished' }
              ],attrs:{"disabled":_vm.encodeStatus !== 'finished'},on:{"click":function($event){_vm.showExtraMenu = true}}},[_c('div',{staticClass:"icon"},[_c('div',{staticClass:"more-icon"})])])],1)])]):_vm._e()]),(!_vm.showHowItWorks && !_vm.showExtraMenu)?_c('div',{class:['interactive-page__container', { mobile: _vm._isMobile }]},[_c('div',{staticClass:"input-block"},[_c('div',{staticClass:"input-block__title"},[_vm._v("input >> text")]),(_vm.encodeStatus !== 'finished')?_c('v-textarea',{staticClass:"input-block__textarea",attrs:{"readonly":_vm.encodeStatus === 'finished' || _vm.encodeStatus === 'proseccing',"placeholder":"Your text here","counter":_vm.textareaLimit,"rows":"2","rules":[
            v =>
              (v || '').length < _vm.textareaLimit ||
              `Text must be ${_vm.textareaLimit} characters or less.`
          ]},model:{value:(_vm.textarea),callback:function ($$v) {_vm.textarea=$$v},expression:"textarea"}}):(_vm.encodeStatus === 'finished')?_c('div',{ref:"contentInput",class:[
            'input-block__result',
            { 'container x-scroll x-mandatory mobile': _vm._isMobile }
          ],attrs:{"id":"input-block__result"}},[(_vm._isMobile)?[_c('ul',{class:['content line', { mobile: _vm._isMobile }]},_vm._l((_vm.mapping),function(obj){return _c('li',{key:`${obj.sequence}-${obj.input}-${obj.end}`,staticClass:"symbol-wrapper",attrs:{"id":`s-${obj.sequence}-${obj.start}-${obj.end}`}},[_c('div',{class:[
                    'symbol-box',
                    {
                      active: _vm.selectedSumbol.find(
                        item =>
                          obj.sequence === item.sequence &&
                          obj.start === item.start
                      )
                    }
                  ]},[_c('div',{class:['symbol'],on:{"click":function($event){return _vm.selectSumbol(obj)},"mouseover":function($event){return _vm.selectSumbol(obj)}}},[(obj.input === '\\n')?[_c('v-tooltip',{attrs:{"content-class":"line-break__tooltip","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("   ")])]}}],null,true)},[_c('span',[_vm._v("line break")])])]:[_vm._v(" "+_vm._s(obj.input === ' ' ? ' ' : obj.input)+" ")]],2)])])}),0)]:_c('div',{staticClass:"line"},_vm._l((_vm.mapping),function(obj,number){return _c('div',{key:number,staticClass:"symbol-wrapper",class:{
                'line-break': obj.input === '\\n'
              },style:({
                'width': obj.input === '\\n'
                && `calc(100% - ${_vm.quantityOfSymbolsBeforeBreak(obj)} * 38px)`
                || '38px'
              })},[_c('div',{class:[
                  'symbol-box',
                  {
                    active: _vm.selectedSumbol.find(
                      item =>
                        obj.sequence === item.sequence &&
                        obj.start === item.start
                    )
                  }
                ]},[_c('div',{class:['symbol'],on:{"click":function($event){return _vm.selectSumbol(obj)},"mouseover":function($event){return _vm.selectSumbol(obj)}}},[(obj.input === '\\n')?[_c('v-tooltip',{attrs:{"content-class":"line-break__tooltip","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("   ")])]}}],null,true)},[_c('span',[_vm._v("line break")])])]:[_vm._v(" "+_vm._s(obj.input === ' ' ? ' ' : obj.input)+" ")]],2)])])}),0)],2):_vm._e(),(_vm.encodeStatus === 'finished' && _vm._isMobile)?_c('div',{staticClass:"input-block__preview"},[_c('pre',[_vm._v(_vm._s(_vm.textarea))])]):_vm._e()],1),_c('div',{staticClass:"output-block"},[_c('div',{staticClass:"output-block__title"},[_vm._v("output >> DNA")]),_c('OutputResult',{ref:"outputResult",attrs:{"encodeResult":_vm.encodeResult,"rows":_vm.rows,"selectedSumbol":_vm.selectedSumbol,"mapping":_vm.mapping},on:{"selectDNASeq":_vm.selectDNASeq}})],1)]):(_vm.showHowItWorks)?_c('div',[_c('HowItWorks',{on:{"close":function($event){_vm.showHowItWorks = false}}})],1):(_vm.showExtraMenu)?_c('div',[_c('ExtraMenu',{attrs:{"shareLink":`${_vm._location}/interactive?job_id=${_vm.data.id}`,"input":`text (${_vm.textarea.length} characters) ${_vm.textarea.length} bytes`,"output":`${_vm.amountSeq} DNA Sequences`,"outputFileUrl":_vm.data.output_file_url || '',"price":_vm.data.price || '$0'},on:{"close":function($event){_vm.showExtraMenu = false}}})],1):_vm._e()]),(!_vm.showHowItWorks)?_c('div',{on:{"click":function($event){return _vm.back()}}},[_c('BackButton')],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }